// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--PNbzO{background-color:rgba(0,0,0,0);padding:5px 0 20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--PNbzO"
};
export default ___CSS_LOADER_EXPORT___;
