// @ts-ignore
import { DEV, UAT, PROD } from './environment.js';
import {version, environment} from '../../app-version.json';

// const ENV = (window as any).ENV;
console.log('ENV', environment);
// 'local' could also include custom setups (eg 'jim'), so we compare against remote environments
const IsLocal = environment !== DEV && environment !== UAT && environment === PROD;
const IsProd = environment === PROD;
const config = (window as any).config || null;

/**
 * RFC4122 compliant GUID generator for JS that is compact and "good enough" for what we want.
 * Should only get called once per session/app load.
 * Used for better SEQ logging.
 */
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const GLOBAL_SESSION_CORRELATION_ID = uuidv4();

export default {
  VERSION: version,
  APIBASE: '/api', // see `webpack.config.js -> devServer.proxy` for local dev server settings
  // APIBASE: 'https://widget-v2-dev.nowbookit.com/api', // To run Dev on local server
  Environment: {
    Value: environment || PROD, // defaults to 'prod' if ENV variable doesn't exist
    IsLocal,
    IsProd
  },
  URLS: {
    admin: config ? config.adminURL : null,
    plugins: config ? config.pluginsURL : null,
    widgetV2URL: config ? config.widgetV2URL : null
  },
  MAX_PEOPLE_PER_BOOKING: 300,
  NARROW_BP: 320, // iPhone 5 (also affects Wix mobile which forces all mobiles to 320px)
  STACKED_BP: 600, // nearly all mobiles will fit into this breakpoint
  STANDARD_BP: 768,
  DEFAULT_SERVICE_DURATION: 90,
  GLOBAL_SESSION_CORRELATION_ID,
  RECAPTCHA_SITE_KEY:'6LeSdy0iAAAAAOm9y3OpSp8o9-WNygA9Wb329jxE'
};
