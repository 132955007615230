// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--lcvRJ{position:fixed;left:0;right:0;top:0;bottom:0;z-index:100;background-color:rgba(255,255,255,.9)}.rootIsDark--WAO-F{background-color:rgba(0,0,0,.8)}.loader--brdb0{width:100%;height:95%;display:flex;flex-direction:column;justify-content:center;align-items:center}.message--h8\\+Tl{margin-top:15px}.messageIsDark--LGae0{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--lcvRJ",
	"rootIsDark": "rootIsDark--WAO-F",
	"loader": "loader--brdb0",
	"message": "message--h8+Tl",
	"messageIsDark": "messageIsDark--LGae0"
};
export default ___CSS_LOADER_EXPORT___;
