import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import { Typography, createStyles, Color, makeStyles } from '@material-ui/core';
import { wrapperStyleType, IWidgetTheme } from 'app/models';
import { renderIf } from 'app/services/utils/utils.service';
import { IStateFromProps, IDispatchFromProps } from './types';
import { ThemeColorsService } from 'app/services/theme/themeColors.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
// @ts-ignore
import * as queryString from 'query-string-es5';
import { themeTypes, IAppQueryStringParams } from "app/models";

const NS = 'MainHeader';

const useStyles = makeStyles((theme: IWidgetTheme) => {
  const queryStringParams = queryString.parse(location.search) as unknown as IAppQueryStringParams;
  const isTranparent = queryStringParams.bg === 'transparent'
  const {secondary, textColor2} = ThemeColorsService.getCommonThemeStyles(theme);
  const { type, palette: { text }} = theme


  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  return createStyles({
    venueTitle: {
      color: isDark ? isTranparent ? (theme.palette.primary as any).contrastText : text.primary : textColor2
    },
    sessionBanner: {
      backgroundColor: secondary.main,
      color: theme.palette.getContrastText(secondary.main)
    }
  })
});


export default function MainHeader({
    isLoading, theme, venue, booking, viewTime, wrapperStyle, businessName, heading, centerLogo,
    appVersion, logoUrl, showAppVersion, isStyleGuide, sessionRemainingTime, isDarkAndLoading, hideHeader
  }: IStateFromProps & IDispatchFromProps) {
  (window as any).appVersion = appVersion;
  console.log('window.appVersion---->', (window as any).appVersion);
  document.title = `${venue?.name || 'Now Book It'}`; // VenueName displayed on browser tab

  const classes = useStyles({theme});

  // 'hideHeader' forces the 'stacked' or 'narrow' style for the expiry strip
  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isLandscape = !IframeResizerService.isStacked(wrapperStyle) && !hideHeader;
  const isStandard = wrapperStyle === wrapperStyleType.standard && !hideHeader;

  return (
    <header className={style.root}>
      <div className={classNames({
        [style.container]: true,
        [style.containerIsHidden]: hideHeader,
        [style.smallTopSpace]: !hideHeader,
        [style.containerHasCenteredLogo]: centerLogo
      })}>

        <div className={classNames({
          [style.venueHeader]: true,
          })}>
        {logoUrl ?
          <div
            data-testid="main-header-logo"
            className={classNames({
              [style.logoWrap]: true,
              [style.logoWrapIsHidden]: hideHeader,
              [style.logoWrapIsLandscape]: isLandscape,
              [style.logoWrapIsStandard]: isStandard
            })}
          >
            <img src={logoUrl} alt={venue ? venue.name : businessName}/>
          </div>
          :
          venue ? '' :
          <Typography
            data-testid="main-header-sub-title-1"
            variant="subtitle1"
            className={classNames({
              [style.businessNameIsHidden]: hideHeader
            })}
          >
            {businessName}
          </Typography>
        }

        {venue ?
          <Typography className={classNames({
            [style.title]: true,
            [style.titleIsHidden]: hideHeader,
            [style.titleIsForcedLight]: isDarkAndLoading,
            [style.titleIsLandscape]: isLandscape,
            [style.titleIsLandscapeHasLogo]: !!logoUrl && isLandscape,
            [style.titleIsStackedNoLogo]: !logoUrl && !isLandscape,
            [style.spaceAround]: true
          })} variant="subtitle1" data-testid="main-header-sub-title-2">

            <span className={classNames({
              [classes.venueTitle]: true,
              [style.venueTitleIsForcedLight]: isDarkAndLoading
            })}>{venue.name}</span>
          </Typography>
          :
          (isLoading || centerLogo ? '' :
            <Typography className={classNames({
              [style.title]: true,
              [style.titleIsHidden]: hideHeader
            })} variant="subtitle1">
              { heading || (isStyleGuide ? 'Style Guide' : 'New Booking')}
            </Typography>
          )
        }
        </div>
        <span className={classNames({
          [style.rightSide]: true,
          [style.rightSideIsHidden]: centerLogo,
          [style.rightSideHasLogo]: !!logoUrl,
          [style.rightSideHasLogoIsLandscape]: !!logoUrl && isLandscape,
          [style.rightSideHasSessionText]: !!sessionRemainingTime
        })} data-testid="main-header-app-version">

          {renderIf(sessionRemainingTime, () => (
            <div className={classNames({
              [style.sessionRemaining]: true,
              [style.sessionRemainingIsRounded]: hideHeader,
              [style.sessionRemainingIsStacked]: !isLandscape,
              [classes.sessionBanner]: true,
              [style.displayColumn]: true
            })} data-testid="main-header-expire-time">
              <div>
                {isStacked && (
                  <Typography className={style.sessionRemainingText}>
                    Your booking is not yet completed, please scroll down to review and enter card details.
                  </Typography>
                )}
              </div>
              <Typography variant="body1" className={style.sessionRemainingText} >
                Your booking will expire in: {sessionRemainingTime}
              </Typography>
            </div>
          ))}
          </span>
        </div>
    </header>
  )
}
