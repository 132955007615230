import { PaletteOptions, SimplePaletteColorOptions, TypeBackground } from "@material-ui/core/styles/createPalette";

export enum colorParamTypes {
  hex = 'hex',
  name = 'name'
}

export enum themeMessageTypes {
  colors = 'colors',
  theme = 'theme',
  font = 'font'
}


export interface IWidgetPalette extends PaletteOptions {
  warning?: SimplePaletteColorOptions;
  success?: SimplePaletteColorOptions;
  error?: SimplePaletteColorOptions;
}

export interface IWidgetBasicColor {
  main?: string; // hex
  light?: string; // hex
}

export interface IPreviewUpdate {
  type: string;
  /**
   * for colors this would be 'hex,ff00ff,00ff00' or 'name,blue,green'
   * for theme this would be 'outlined-light'
   */
  value: string;
}

export interface IColorsFromQueryString {
  palette: PaletteOptions;
  defaultColors: boolean;
}
