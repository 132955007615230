// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainContainer--gal7c{padding:20px 10px 16px;width:100%;min-width:320px;max-width:1024px;margin:0 auto}.termsWrap--MDL7y{display:flex;justify-content:flex-end;margin-top:11px}.termsWrapIsStacked--9p28L{justify-content:center;margin-top:10px}.termsWrapIsStackedIsPayment--2mpuy{margin-top:12px}.simplePage--OSu63{padding-bottom:30px}.mockBtnWrap--Iyf2d{position:fixed;top:5px;left:5px;z-index:100}.mockBtnWrap--Iyf2d>button{font-size:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "mainContainer--gal7c",
	"termsWrap": "termsWrap--MDL7y",
	"termsWrapIsStacked": "termsWrapIsStacked--9p28L",
	"termsWrapIsStackedIsPayment": "termsWrapIsStackedIsPayment--2mpuy",
	"simplePage": "simplePage--OSu63",
	"mockBtnWrap": "mockBtnWrap--Iyf2d"
};
export default ___CSS_LOADER_EXPORT___;
