import { IBookingStatus } from './bookingStatusTypes';
import { IAnalyticsKeys, IVenueMinimal, preAuthStatus } from 'shared-types/WidgetTypes';
import { IDiarySchedule, IPouchDBInstance, ISection, ISectionGroup } from 'shared-types/DiaryTypes';

export const WALKIN_DEFAULT_NAME = 'Walk In';

export enum DiaryMobileVersion {
  LiteVersion = 'LiteVersion',
  PlusVersion = 'PlusVersion',
}
export enum NBIProject {
  DIARY = 'DIARY',
  DIARY_MOBILE = 'DIARY_MOBILE',
}
export enum ToastType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

/**
 * @todo: it may make sense now to change 'WaitList' to 'WalkIn', since NBI-1453 made it impossible to have a waitlist
 * item unless it is either standby or walkin. This however would involve a back end change as well. severity: medium
 */
export enum BookingType {
  Booking = 'Booking',
  WaitList = 'WaitList',
  StandbyList = 'StandbyList',
}

export enum SEARCH_FIELDS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  EMAIL = 'email',
  COMPANY = 'company',
}

export enum CustomerTypes {
  inline = 'inline',
  new = 'new',
  existing = 'existing',
}

export enum BookingBaseType {
  booking = 'booking',
  event = 'event',
  // @todo: determine if we also need private function here
}

export enum BookingMethods {
  phone = 'phone',
  walkin = 'walkin',
  internet = 'online',
  email = 'email',
}
export enum PaymentTypes {
  NoPayment = 'NoPayment', // Default
  Deposit = 'Deposit',
  FullPayment = 'FullPayment',
  PreAuth = 'PreAuth',
  FunctionPayment = 'FunctionPayment',
  MenuOptions = 'MenuOptions', // Only a map/type view
}

export enum PricingTypes {
  PerPax = 'PerPax',
  PerBooking = 'PerBooking',
}

export interface ITableOptionValidity {
  free?: boolean;
  valid?: boolean;
}

export interface ITableOptionValidityRef extends ITableOptionValidity {
  table?: ITableItem;
}

export interface ITableItemAbstract extends ITableOptionValidity {
  _id?: string; // "table_3Z9QEO88JE2P4_1597633785419"
  sectionId?: string; // "section_9BTVP2I5KZMWI"
  tablesList?: ITableItem[]; // this is a list of table objects, used when a table join exists
  priority: number;
  capacity: number;
  availableOption: string; // "all"
  minCapacity: number; // 1
  name?: string; // "Big table"
  online?: boolean;
  order?: number;
  shape?: string; // "rect"
  shared?: boolean;
  type?: string; // "table"
  walkinOnly: boolean;
  rotation?: number;
  position?: { x: number; y: number };
  chairs?: any; // {0: 1, 1: 1},
  markedToAdd?: boolean;
  problemReasons?: string[];
}

export interface ITableItem extends ITableItemAbstract {
  tables?: string[]; // this is a list of table ids, used when a table join exists.
  layoutName?: string; // "First" // this field is added for blockout save function(Horita)
  problemReasons?: string[];
}

export interface RequiredTable extends ITableItem {
  available: boolean;
  validTable: boolean;
}

/**
 * @todo: we may need to replace IPaymentDetails with IPaymentDetailsBase - further investigation needed. severity: medium
 */
export interface IPaymentDetails {
  menuOptions: string[]; // ["menuoption_F2CKA82APR5PH_1586299562563"]
  menuOptionsUpsell: string[]; // ["menuoption_F2CKA82APR5PH_1586299562563"]
  options?: IServicePaymentOption[];
  optionsUpsell?: IServicePaymentOption[];
  optionsFrom: unknown;
  optionsTo: unknown;
  paymentType: PaymentTypes;
  peopleRequired: number;
  singleMenuPerBooking: boolean;
  price?: number;
  pricingType?: PricingTypes;
}

export interface IUpdateActiveChildMenuOptionPayload {
  implicitChildMenuOptions: ISimpleBookingOption[];
  explicitChildMenuOptions: ISimpleBookingOption[];
}

export interface IUpdateCachedChildMenuOptionDetails {
  options: ISimpleBookingOption[];
  fullRefresh: boolean;
}

export interface ISimpleBookingOption {
  description?: string;
  id?: string; // "menuoption_UAQAZETMPS9T7_1573535792474"
  label?: string;
  link?: string;
  name?: string;
  price?: number;
  provider?: string; // "Nbi"
  tag?: string; // "tag_birthday"
  paymentType: servicePaymentType;
  paymentTypeOverride?: IPaymentTypeOverride;
}

export interface IPaymentTypeOverride {
  paymentType: servicePaymentType; // for when child booking options dictate the payment type (on noPayment parents)
  amount?: number;
  hasError: boolean;
}

export interface IServicePaymentOption extends ISimpleBookingOption {
  childMenuOptionIds?: string[];
  explicitChildMenuOptionIds?: string[];
}

export interface IMenuOption extends IServicePaymentOption {
  isStandaloneItem: boolean;
  pricingType: PricingTypes;
  type: string; // "menuoption"
  _id: string;
}

/**
 * contains extra properties, but which may not be needed for general `IServicePaymentOption` usage
 * extends `Partial<IServicePaymentOption>` because `id` is not present initially when fetching from pouch (which uses
 * `_id` instead) but gets added later.
 */
export interface IPouchServicePaymentOption extends Partial<IServicePaymentOption> {
  _id: string; //"menuoption_N39GUAUKM6T7M_1653363125373"
  type: string; //"menuoption"
  isStandaloneItem: boolean;
  isArchived: boolean;
  errorMessage: any;
  _rev: string;
}

export interface IServicePaymentDetails {
  hasPromotion?: boolean;
  maxPeoplePerBookingOverride?: number;
  options: IServicePaymentOption[];
  optionsUpsell?: IServicePaymentOption[];
  optionsFrom: unknown;
  optionsTo: unknown;
  paymentType: servicePaymentType;
  peopleRequired: number;
  price: number; // dollars
  singleMenuPerBooking: boolean;
  pricingType?: PricingTypes;
  fee?: number;
  upsellHeading?: string;
  upsellDescription?: string;
}

/**
 * Properties for pre-auth payment type
 */
export interface IPaymentSummary {
  amount: number;
  amountDue: number;
  amountHeld: string; // "2.00"
  amountPaid: number;
  currencyCode: string;
  hasPromotion: boolean;
  paymentType: servicePaymentType;
  preAuthChargedAmount: number;
  preAuthChargeIfWithinHours: number;
  preAuthHeldDate: string; // "2022-04-19T07:34:00.2929921+00:00"
  preAuthReleasedAmount: number;
  preAuthStatus: preAuthStatus;
  pricingType?: PricingTypes;
  transactionId?: any;
  promotionCode?: string;
  cardLast4?: string; //Last four digits used to pay for this booking, if available.
}

/**
 * Properties for non pre-auth payment type
 */
export interface IPaymentPending {
  discountAmount: number;
  fee: number;
  nbiFee: number;
  paymentType: servicePaymentType;
  peopleRequired: number;
  price: number;
  transactionFeeDescription: string;
  pricingType?: PricingTypes;
}

/**
 * similar to IServicePaymentDetails
 * @todo: We may need to replace all instances of IPaymentDetails with IPaymentDetailsBase, but needs further investigation. Severity: medium
 */
export interface IPaymentDetailsBase {
  discountPercentage?: number; // 90
  paymentType: servicePaymentType;
  peopleRequired: number; // 1
  promotionCode?: string; // "halfprice"
  singleMenuPerBooking: boolean;
  pricingType?: PricingTypes;
  price?: number;
  menuOptions: string[]; // ["menuoption_EJUCQOQA94JTG_1587697160128"]
  menuOptionsUpsell?: string[];
  upsellDescription?: string;
  upsellHeading?: string;
  discountAmount?: number;
  options?: IServicePaymentOption[];
  optionsUpsell?: IServicePaymentOption[];
}

export interface IAdditionalPaymentDetails extends IPaymentDetailsBase {
  optionsFrom: string; // "2021/03/23 00:00"
  optionsTo: string; // "2021/03/30 00:00"
}

export interface IPaymentType {
  amount: number; // 132.5,
  paymentTypeName: servicePaymentType; // "FullPayment"
  amountDue: number;
  discountAmount: number;
}

export interface IBookingLink {
  linkName: string;
  linkURL: string;
}

// these 2 must match
export type TypeBookingCustomerProps =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'company'
  | 'notes';
export interface IBookingCustomerProps {
  firstName: string; // "Peter"
  lastName: string; //"Quill"
  email: string; // "peter@quill.com"
  phone: string;
  company: string; // "GoG";
  subscribed?: boolean;
}

interface IBookingAbstract extends IBookingCustomerProps {
  sectionMaxOccupancy?: string;
  bookingRef: string; //"03495357"
  partner?: string; //e.g. "Function Tracker"
  bookingMin: number;
  customerId: string; // "customer_9ea76edb-914f-4526-b3e8-bdcbc208ce91"
  customersCovid: unknown[];
  duration: string | number; // should be number (mins) but due to bug, could be string
  hasCustomerNotes: boolean;
  hasManagerNotes: boolean;
  notes: string;
  hasVisitedMultipleVenues: boolean;
  selectedMenuOptions?: IBookingMenuOption[];
  selectedOptions?: ISavedBookingSelectedOptions[];
  selectedUpsellOptions?: IBookingMenuOption[];
  lastModifiedDate: string; // "2020-09-03T03:24:55.403Z"
  locked: boolean;
  method: BookingMethods;
  orgTime: string; // "2020/09/04 09:00"
  paymentDetails: IPaymentDetailsBase;
  paymentSummary?: IPaymentSummary;
  paymentPending?: IPaymentPending;
  people: number; // 2
  preferredSectionId: string; // "section_9BTVP2I5KZMWI"
  primaryTableId: string;
  serviceId: string; // "service_MWNR2VZS6BAO1_1587075416493"
  serviceName: string;
  serviceType: string;
  standByConfirmationExpiry?: string;
  status: IBookingStatus;
  tables: ITableItem[];
  tags: IBookingTag[];
  isStandbyListFlexibleTime?: boolean;
  type: BookingBaseType; // "booking"
  bookingType: BookingType; // 0
  sharedOverCapacity: boolean;
  overdue: boolean;
  isManualJoin: boolean;
  functionTracker?: boolean;
  _id: string; // "booking_2020-09-04_QLBY104Y0T0W2_1599103495357"
  _rev?: string; // gets added after saved to pouch
  staffName?: string;
  movedFromBookingId?: string;
  bookingLinks?: IBookingLink[];
  communicationsEnabled?: boolean;
  hasVIPCustomerSMSSent?: boolean;
  isPreAuthPayment?: boolean;
  isSingleMenuOptionService?: boolean;
  visits?: number;
  additionalPaymentDetails?: any[];
  newOnlineBooking?: boolean;
  serviceIdOverRide?: string;
  isSaving?: boolean;
  id?: string;
  bookingMethod?: string; // Horita currently not sure
  bookingIndex?: number; //1
  disableEmailSmsNotification?: boolean;
  locks?: IBookingLock[];
  cancelledReason?: BookingCancelledReason;
}

export interface IBooking extends IBookingAbstract {
  time: Date;
  bookedAt: Date;
  bookingEnd: Date;
  tablesAvaiable?: boolean; // this is wrong spell
  selectedMenuOptions?: IBookingMenuOption[];
  recurringBookingId?: string;
  bookedBy?: IBookedByCustomerDetail;
  isBookedBy?: boolean;
  bookedByNotify?: {
    notifySms?: boolean;
    notifyEmail?: boolean;
  };
  isDateChangedToNextDay?: boolean;
  // note: lastModifiedDate is still a string
}

/**
 * when booking object comes straight from pouch, dates are strings, until they get converted in JS
 */
export interface IBookingFromPouch extends IBookingAbstract {
  time: string; // "2022/12/05 11:15"
  bookedAt: string; // "2022-12-04T20:08:07.469Z"
  bookingEnd: string; //"2022-12-04T20:08:09.148Z"
  bookedBy?: IBookedByCustomerDetail;
  isBookedBy?: boolean;
  bookedByNotify?: {
    notifySms?: boolean;
    notifyEmail?: boolean;
  };
}

export interface IBookingNotificationItem extends IBooking {
  modifiedProperties?: string;
  customer?: ICustomer;
  notify?: boolean;
  smsNotify?: boolean;
  bookedByNotify?: {
    notifySms?: boolean;
    notifyEmail?: boolean;
  };
}

/**
 * this interface is for use when building out the booking object before saving it,
 * where not all properties have been populated yet.
 */
export interface IBookingUnsaved extends Omit<IBooking, 'paymentDetails'> {
  paymentDetails: Partial<IPaymentDetailsBase>;
}

export interface ICustomer {
  birthdayDate?: number;
  birthdayMonth?: number;
  birthdayYear?: number;
  company?: string;
  email: string; // "peter@parker.com"
  firstName: string; // "Peter"
  fullName: string; // "Peter Parker"
  hasVisitedMultipleVenues?: boolean;
  lastModifiedDate: string; // "2020-09-22T03:33:10.3767529+00:00"
  lastName: string; // "Parker"
  managerNotes: string;
  noShowCount: number;
  notes: string;
  phone: string; // "+61414000111"
  phone2: string;
  phoneNational: string; //"0414562166"
  postcode: string;
  streetAddress: string;
  subscribed: boolean;
  suburb: string;
  tags: IBookingTag[];
  type: string; // "customer"
  venueIds: string[];
  _deleted?: boolean;
  label?: string;
  temporary?: boolean;
  updatingCustomer?: boolean;
  customerType?: string;
  country?: string;

  _id: string; // "customer_8b75784d-b292-46dd-bec6-ba92daa7c911"
  _rev?: string;
  id?: string; // "customer_3357019e-0254-4127-a0e8-634e5fc5cb9b"
}

export enum ServiceEventType {
  PrivateFunction = 'PrivateFunction',
  Event = 'Event',
}

export interface ILayout {
  name: string;
  sectionId: string;
  order: number;
  structures?: {
    height: number;
    position: { x: number; y: number };
    rotation: number;
    subType: string; // "wall"
    type: string; // "structure"
    width: number; //80
    _id: string;
  }[];
  tables: string[];
  tablesJoins: ITableItem[];
  box?: { h: number; w: number; x: number; y: number; x2: number; y2: number };
  _id: string;
}

export interface IServiceSubset {
  paymentDetails: IPaymentDetailsBase;
  additionalPaymentDetails: IAdditionalPaymentDetails[];
}

export interface IPrivateFunctionDiaryItem extends IServiceItem {
  people: number;
  company: string;
  customerId: string;
  date: Date;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface IServiceItem {
  _id: string; // "service_MWNR2VZS6BAO1_1587075416493"
  type: string; // "AllDay", 'Event', 'event'
  eventType?: ServiceEventType;
  bookingPAX?: number;
  startTime: string | Date; // Fri Sep 04 2020 06:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  endTime: string | Date; // Fri Sep 04 2020 22:00:00 GMT+1000 (Australian Eastern Standard Time) {}

  sectionLayouts: ISectionLayoutItem[];

  Colour: string; // "booking-time-orange"
  active: boolean;
  additionalPaymentDetails: IAdditionalPaymentDetails[];
  bookingDuration: number; // 30
  description: string;
  index: number; // 0
  intervalsCustom: { times: string; values: string | number; intervalDuration: string | number }[];
  lastBookingTime: Date; // Fri Sep 04 2020 21:45:00 GMT+1000 (Australian Eastern Standard Time) {}
  name: string; // "All day"
  online: boolean;
  paymentDetails: IPaymentDetails;
  policyAgreement: boolean;
  policyAgreementText: string; // "Agree to stuff"
  tablesIdsList: string[]; // ["table_S2HVR52GTHI60_1585723196136"]

  enableCustomPaxPerIntervals: boolean;
  maxCoversPerInterval: number;
  maxBookingsPerInterval: number;

  boookingInterval?: number;
  firstBookingTime?: Date;
}

export interface ISectionLayoutItem {
  sectionId: string; //"section_BW2QFMOCJZLKH"
  sectionNotUsed: boolean;
  layoutId: string;
  sectionClosed?: boolean;
}

export interface IServiceListView {
  serviceIndex?: number;
  name: string;
  isListTop?: boolean | number;
  service?: IServiceItem | number;
  order?: number;
  services?: IServiceItem[];
}

export interface IServiceLayoutItem {
  name: string; // "All day"
  serviceIndex: number; // 0
  serviceId: string; //"service_MWNR2VZS6BAO1_1587075416493"

  endMin: number; // 1320
  endTime: string | Date; // string | Date Fri Sep 04 2020 22:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  layoutId: string; // "layout_VWN60GYEO62GN"
  sectionClosed: boolean;
  sectionId: string; // "section_9BTVP2I5KZMWI"
  sectionNotUsed: boolean;
  sectionOpen: boolean;
  startMin: number; // 360
  startTime: string | Date; // string | Date Fri Sep 04 2020 06:00:00 GMT+1000 (Australian Eastern Standard Time) {}
  totalMin: number; // 960
}

export enum BlockoutType {
  event = 'event',
  blockout = 'blockout',
}

export interface IBlockout {
  _id: string;
  startDateTime: Date; // "2024/04/01 02:01" string type is possible(horita)
  startDateTimeStr?: string; // string version of date
  endDateTime: Date; // "2024/04/01 02:01" string type is possible(horita)
  endDateTimeStr?: string; // string version of date
  blockedSections: string[];
  blockedTables: ITableItem[];
  type: BlockoutType;
  excludedDates?: any[]; //any[]; //currently not sure  Date[] | string[]

  name?: string; //"testBlockout" // this field is added for blockout save function(Horita)
  repeatType?: string; //"no-repeat" // this field is added for blockout save function(Horita)
  weeklyDay?: {
    [key: string]: boolean;
  }; // {0: true 1: true} object, currently not sure // this field is added for blockout save function(Horita)
  createdDate?: string; // "2022-05-17T00:40:21.167Z" this field is added for blockout save function(Horita)
  updatedDate?: string; // this field is added for blockout save function(Horita)
  _rev?: string; // this field is added for blockout save function(Horita)
  startTime?: Date | string; // string | Date "2024/04/01 02:01" "14:03" this field is added for blockout save function(Horita)
  endTime?: Date | string; // "2024/05/02 01:00" this field is added for blockout save function(Horita)
  startDate?: Date | string; // "2027/03/04 00:00" this field is added for blockout save function(Horita)
  endDate?: Date | string; // "2027/03/04 00:00" this field is added for blockout save function(Horita)
  closed?: boolean;
  widgetMessage?: string; // this field is added for blockout save function(Horita)
  dailyRepeat?: string; // this field is added for blockout save function(Horita)
  monthlyType?: string; // this field is added for blockout save function(Horita)
  monthlyOccurrence?: string; // this field is added for blockout save function(Horita)
  monthlyDay?: string; // this field is added for blockout save function(Horita)
  monthlyDate?: number; // this field is added for blockout save function(Horita)
  yearlyMonth?: string; // this field is added for blockout save function(Horita)
  yearlyDay?: number; // this field is added for blockout save function(Horita)
  createdBy?: NBIProject; //UI-737, show delete Icon if createdBy is DIARY_MOBILE
}
export interface IBookingMenuOptionExtras {
  isSameForAll: boolean;
  explicitChildMenuOptions: IBookingMenuOption[][]; // multi-dimensional array
  implicitChildMenuOptions: IBookingMenuOption[];
}

export interface IBookingMenuOptionExtrasUpdater extends IBookingMenuOptionExtras {
  parentId?: string;
  parentLabel: string;
  upsellDescription?: string;
  parentQuantity: number;
}

export interface IBookingOptionPayment {
  paymentTypeOverride: { paymentType: servicePaymentType; hasError: boolean };
  id: string;
}

export interface IBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: IBookingMenuOptionExtras; // expanded list of child booking options
  isUpsellItem?: boolean; // will be true for upsell BO
}
export interface ISavedBookingMenuOption {
  quantity: number;
  menuOptionId: string;
  extras?: ISavedExtrasOption[]; // flat list of child booking options for backend
  isUpsellItem?: boolean; // will be true for upsell BO
}

export interface ISavedExtrasOption {
  quantity: number;
  menuOptionId: string;
  isExplicit: boolean;
}

export interface IOption {
  price: number;
  childOptions?: IOption[];
}

export interface IMenuOptionsResponse {
  statusText: string; // OK
  status: number; // 200
  data: ISimpleBookingOption[];
}

export interface ISavedBookingSelectedOptions {
  extras: ISavedExtras[];
  id: string; // "menuoption_GCYRCFH5MFORV_1589160518076"
  label: string; // "free buffet"
  name: string; // "free buffet"
  paymentType: servicePaymentType;
  price: number; // 0
  quantity: number; // 2
  isUpsellItem?: boolean;
  tag?: IBookingTag | string;
}

export interface ISavedExtras {
  id?: string; // "menuoption_YOSOQUWBWJO8N_1587443464464"
  isExplicit: boolean; // true
  label?: string; // "Free lunch"
  name?: string; // "Free lunch"
  paymentType?: servicePaymentType;
  price?: number; // ISavedBookingSelectedOptions
  quantity: number; // 1
  menuOptionId?: string;
  tag?: IBookingTag | string;
}

export enum servicePaymentType {
  fullPayment = 'FullPayment',
  noPayment = 'NoPayment',
  deposit = 'Deposit',
  preAuth = 'PreAuth',
  functionPayment = 'FunctionPayment',
}

export enum TimeStatus {
  available = 'available',
  unavailable = 'unavailable',
}

export interface ITableDate extends IBookingTime, Date {
  state: string; // "selected"
}

export interface IInfoAtTime {
  time: Date;
  maxCovers: number;
  coversReached: number;
  bookingsAtTime: unknown[]; // this is probably an IBooking array
  vaildTablesAtTime: ITableItem[];
  invalidTablesAtTime?: ITableItem[]; // not ready for this yet (Horita) Nhat asked this makes as optional. NBI-6152
  freeTablesAtTime: ITableItem[];
  vaidJoinsAtTime: ITableItem[];
  status: TimeStatus;
  errorMgs: string[];
  warningMsgs: string[];
  open: boolean;
  service: string; // name of the service
  isManualTime?: boolean;
}

export interface IBookingTime extends IInfoAtTime {
  table: ITableItem;
}

export interface IBookingTagType {
  name: string; // "Occasion"
  type: string; // "tagtype"
  _id?: string; // "tagtype_occasion"
  id?: string;
  _rev?: string;
  $$hashKey?: string;
}

export interface IBookingTag {
  colour?: string; //"#000000"
  icon: string; //"birthday"
  name: string; //"Birthday"
  online?: boolean;
  order?: number;
  permanent?: boolean;
  tagtype?: IBookingTagType;
  type?: string; // "tag"
  selected?: boolean;
  editable?: boolean;
  noneditable?: boolean;
  _id?: string; // "tag_birthday"
  _rev?: string;
}

export interface ICood {
  x: number;
  y: number;
}

export interface ISectionLookup {
  name: string; // "Beer Garden"
  order: number; // 1
  position: ICood;
  type: string; // "section"
  _id: string; // "section_9BTVP2I5KZMWI"
}

export interface IResponse<T> {
  statusText?: string; // OK
  status: number; // 200
  data: T;
}

export interface ITableSectionList {
  tableSelectionList: ITableItem[];
  tableSelectionListWithJoins: ITableItem[];
  tableStatusLookUps: any;
}

export interface IManageBookingConfig {
  title: string;
  message: string;
  hideDetails: boolean;
  hideButtons: boolean;
}

export interface IPosIntegationDetails {
  posVendorName: string;
  markLocationBookingsLocked: boolean;
}

// very similar to IOwnedVenue, but not identical.
// If using in Diary or Diary Mobile, use IDiaryVenue instead, as IVenueMinimal in used in widget and some properties are not compatible
export interface IVenue extends IVenueMinimal {
  id: number;
  accountId?: string;
  currency: string; // USD, AUD, GBP, etc
  timeZoneId: string;
  clientSideEncryptKey: string;
  analytics: IAnalyticsKeys;
  preAuthReleasingWindow?: number;
  maxPeoplePerBooking?: number;

  // @todo: do these existing in widget?
  couchName?: string; //"db9"
  couchUrl?: string; //"http://10.211.55.3:5984/db9"
  posIntegrations?: IPosIntegationDetails[];
  pins: string[];
  reducedCouchUrl: string;
  isUsingAccountWidgetSettings: boolean;
  theme: string;
  accentColour: string;
  font: string;
  widgetVersion: number;

  createdAt: string; //"2020-04-01T06:39:37.5265894+00:00"
  enableSmsWaitList: boolean;
  isCovidGuestMode: boolean;
  isGiftCardActive: boolean;
  isOnlineCustomersEnabled: boolean;
  isPOSActive: boolean;
  isPaymentsConfigured: boolean;
  isTakeAwayMode: boolean;
  isTelemetryEnabled: boolean;
  maxBookingOptionsPerInterval: number;
  maxPeoplePerOnlineBooking: number;
  postcode: string; //"2000"
  reducedCouchName: string; //"db9r"
  reducedCouchPassword: string; //null
  reducedCouchUsername: string; //null
  renewalDate: string; //"2020-04-01T06:39:29.482+00:00"
  standByList: boolean;
  subscriptionType: string; //"Perpetual"
  suburb: string; //"Woy Woy"
  tags: IBookingTag[];
  url: string; //null
  username: string; //null
  smsEnabled: boolean;
  activeModifiedAt?: string; //"2019-12-12T22:15:50.4330711+00:00"
  enableDiarySettings?: boolean;
  enableNewCustomerForm?: boolean;
  enableBookedByOnDiary?: boolean;
  enableBookedByOnWidget?: boolean;
}

export interface ILayoutMinimalAbstract {
  _id: string; // "layout_16QV80IGK18U2",
  name: string; // "Garden Front Layout Spacious Rectangles",
  sectionId: string; // "section_T4T83LMU3BFAO",
  tables: ITableItem[];
  tablesJoins: ITableItemAbstract[];
}

export interface ILayoutMinimal {
  tablesJoins: ITableItem[];
}

export interface IBookingPayment {
  amountPaid?: number; // amountPaid will be undefined if pre-auth is not yet authorized
  price: number;
  fee?: number;
  paymentType: servicePaymentType;

  amountDue: number;
  discountAmount: number;
  peopleRequired: number;
  promotionCode?: string;
  discountApplied?: boolean;

  transactionId?: string;
  currency?: string; // think this is just for pre-auth
  cardLast4?: string;
}

export interface ICalculatePaymentResult {
  amount: number;
  creditCardFee: number;
  discountAmount: number;
  menuItemsSubTotal: number; // just the value of the booking options
  nbiBookingFee: number;
  paymentType: servicePaymentType;
  paymentTypeName: servicePaymentType;
  pricingType: PricingTypes;
  serviceBasedFeeTotal: number; // just the price of the service
  venueFee: number;
}

export const SECTION_ANY_ID = 'any';
export const SECTION_ANY_NAME = 'Any';

export interface IDetectPhone {
  isIPhone: boolean;
  isPhone: boolean;
}

export enum PaymentKind {
  stripeStandard = 0,
  stripe3DSecure = 1,
  stripePreauth = 2,
  ewayStandard = 3,
  ewayPreauth = 4,
}

export enum PaymentTargetType {
  person = 'person',
  booking = 'booking',
}

// table join prefix for ids (front end only)
export const JOIN_PREFIX = 'join_';

export interface IThemeValue {
  theme: string;
  accentColour: string;
  font: string;
  widgetVersion: number;
}

export enum AustralianStates {
  VIC = 'VIC',
  TAS = 'TAS',
  QLD = 'QLD',
  WA = 'WA',
  SA = 'SA',
  NT = 'NT',
  ACT = 'ACT',
  NSW = 'NSW',
}

export enum BookingStateChangeTypes {
  CREATED = 'created',
  DELETED = 'deleted',
  MOVED = 'moved',
  UPDATED = 'updated',
  PRE_AUTH_CHARGED = 'pre-auth charged',
  PRE_AUTH_RELEASED = 'pre-auth released',
  DATE_CHANGED = 'date changed',
}

export interface IPouchRowItem<T> {
  id: string;
  key: string;
  value: { rev: string };
  doc: T;
}

export interface IPouchRows<T> {
  total_rows: number;
  offset: number;
  rows: IPouchRowItem<T>[];
}

export interface IPouchUpdateResponse {
  id: string;
  rev: string;
  ok: boolean;
}

export interface IBookingUpdateService {
  update: (
    booking: IBooking,
    orgBooking: IBooking,
    bookingCustomer?: any,
    notify?: any,
    staff?: any,
    schedule?: any,
    changesType?: any,
    dontChangeLastModifiedDate?: boolean
  ) => Promise<IBooking> | boolean;
}

export interface IObjectMap<T> {
  [key: string]: T[];
}

export interface ISectionItem {
  maxOccupancy?: number;
  _id: any; // can be 'any', so probably a string
  order: number;
  name: string;
}

export interface IPouchCreds {
  username: string;
  password: string;
  url: string;
}

export const FunctionTrackerInfo = {
  id: 'function_tracker',
  name: 'Function Tracker',
};

export interface IDoc {
  order?: number;
  ids: string[];
  isListTop?: boolean;
  name: string;
  _id?: string;
  _rev?: string;
}

export interface ICouchUser {
  couchUrl: string; //"http://10.211.55.2:5984/db4021",
  couchUsername: string; //"U7674b3c3-63a3-42ac-9846-6ba840645a09",
  couchPassword: string; //"Pc814fd6c-d287-43ed-a93c-7da4abdfed21",
  reducedCouchUrl: string; //"http://10.211.55.2:5984/db4021r",
  reducedCouchUsername: string; //"Ua5611485-a06a-477d-a8c2-81d6d4dcffb0",
  reducedCouchPassword: string; //"P117be7ed-bb43-4c58-b6f1-2588ac118fbb"
}
export interface IAffectedBooking {
  tableEffected: boolean;
  timeEffected: boolean;
  booking: IBooking;
}

export interface IPouchService {
  selectDatabase?: (date: Date | string) => IPouchDBInstance;
  getDBOnline?: () => IPouchDBInstance;
  getDB?: () => IPouchDBInstance;
  getDBOnlineReducedAsFirstOption?: () => IPouchDBInstance;
  getNewOfflineBookingsInstance?: () => IPouchDBInstance;
  getNewId?: (param: any) => any;
}

export enum AdminServiceLog {
  ERRORLOG = 'error',
  WARNLOG = 'warn',
  INFOLOG = 'info',
}

export const WIDGET_CANCELLATION_SIGNAL = 'WidgetCancellationSignal';

export enum FetchingStatus {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum BlockoutRepeatTypes {
  Close = 'close',
  NoRepeat = 'no-repeat', // Default
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export interface ITrackingService {
  trackEvent: (eventName: any, values?: any) => void;
  trackPageView: (pageName: string) => void;
  trackUser: (user: any, venue: any) => void;
}

export interface IReplicateSubscriptionItem {
  cancelled?: boolean;
  to?: (url: string, config: any) => IReplicateSubscriptionItem;
  from?: (url: string, config: any) => IReplicateSubscriptionItem;
  on?: (eventName: string, cb: (info: any) => void) => IReplicateSubscriptionItem;
}

export interface IReplicateSubscription {
  initialFrom: IReplicateSubscriptionItem;
  liveFrom: IReplicateSubscriptionItem;
  liveTo: IReplicateSubscriptionItem;
}

export enum StatusCharacterType {
  typeA = 1,
  typeB = 2,
}

export interface IStatusCharacter {
  large: boolean;
  text: string;
  type?: StatusCharacterType; // only relevant if large equals true
}
export interface IBookingNotificationEnableStatus {
  emailNotify: boolean;
  smsNotify: boolean;
}

export interface IScheduleLookup {
  [key: string]: {
    schedule: IDiarySchedule;
    dateAdded: Date;
  };
}

export interface IBookingLock {
  name: string; //Unique name of this lock
  expiresUtc: Date; //When this lock expires
  createdUtc: Date; //Date this lock was created
  lockType: string; //Type of lock (edit, view, etc)
  lockedBy: string; //Who this lock was created by. (api, diary, etc)
  reason: string; //Reason this lock was applied
}

export interface ISectionLookupCache {
  [key: string]: ISection | ISectionGroup;
}

//Indicates reason for booking cancellation, if any.
export enum BookingCancelledReason {
  unspecified = 'unspecified',
  editMoved = 'editMoved',
}

export enum StaffNamePanelType {
  isNew = 'isNew',
  isEdit = 'isEdit',
  isQuickStatus = 'isQuickStatus',
  isStandard = 'isStandard',
}
export enum CustomerMode {
  NEW = 'NEW_CUSTOMER_VIEW',
  DISPLAY = 'DISPLAY_CUSTOMER_VIEW',
  SEARCH = 'SEARCH_CUSTOMER_VIEW',
  EDIT = 'EDIT_CUSTOMER_VIEW',
}
export interface ISearchingCustomer {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}
export interface IBookedByCustomerDetail {
  phone?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  isShown?: boolean;
  disableEmailSmsNotification?: boolean;
  notifyEmail?: boolean;
  notifySms?: boolean;
}
export interface IBookedByValidation {
  isBookedByValid: boolean;
  bookedByErrors: string[];
  isBookedByEmailValid: boolean;
  isBookedByPhoneValid: boolean;
}

export enum ValidationErrorType {
  email = 'email',
  phone = 'phone',
  customer = 'customer',
  existingCustomer = 'existingCustomer',
  table = 'table',
  staff = 'staff',
  time = 'time',
  pax = 'pax',
  bookingOptions = 'bookingOptions',
  upsellOptions = 'upsellOptions',
  status = 'status',
  payment = 'payment',
  bookedBy = 'bookedBy',
  recurringBooking = 'recurringBooking'
}

export enum BookingCreatorPanelType {
  bookingDetails = 'bookingDetails',
  customerDetails = 'customerDetails',
  upsellOptions = 'upsellOptions',
  bookingOptions = 'bookingOptions',
  staff = 'staff',
  status = 'status',
  payment = 'payment',
  bookedBy = 'bookedBy',
  recurringBooking = 'recurringBooking'
}

export interface IValidationErrorListItem {
  type: ValidationErrorType;
  msg: string;
  panel?: BookingCreatorPanelType;
}
