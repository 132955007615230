import { Observable } from "rxjs";
import { wrapperStyleType } from "app/models";
import appValues from "app/constants/appValues";

// @ts-ignore
import * as queryString from 'query-string-es5';

const NS = 'IframeResizerService';

interface IPageInfo {
  iframeWidth: number;
}

export class IframeResizerService {

  static isStacked(type: wrapperStyleType): boolean {
    return type === wrapperStyleType.narrow || type === wrapperStyleType.stacked;
  }

  static handleSizeChange(): Observable<wrapperStyleType> {

    return new Observable(observer => {

      const onChanged = (w: number) => {
        if (w <= appValues.NARROW_BP) {
          observer.next(wrapperStyleType.narrow);
        } else if (w <= appValues.STACKED_BP) {
          observer.next(wrapperStyleType.stacked);
        } else if (w <= appValues.STANDARD_BP) {
          observer.next(wrapperStyleType.standard);
        } else {
          observer.next(wrapperStyleType.wide);
        }
      };

      // when widget is NOT inside an iframe
      const winResize = () => onChanged(window.innerWidth);
      (window as any).addEventListener('resize', winResize);
      (window as any).addEventListener('DOMContentLoaded', winResize);

      (window as any).iFrameResizer = {
        onReady: function() {
          if ('parentIFrame' in window) {

            (window as any).removeEventListener('resize', winResize);
            (window as any).removeEventListener('DOMContentLoaded', winResize);

            // when widget IS inside an iframe
            (window as any).parentIFrame.getPageInfo((info: IPageInfo) => {
              onChanged(info.iframeWidth);
            });
          }
        },
        onMessage: function (msg: string) {
          if (msg === 'resize') {
            winResize();
          }
        }
      }
    });
  }

  static notifyParentIFrameOfSectionChange(sectionName: string): void {
    const params: any = queryString.parse(location.search) as unknown;

    // optionally disable mobile scroll by adding '&mobilescroll=none' to iframe src
    if (params.mobilescroll === 'none') return;

    const win: any = (window as any);
    const isEmbeddedIFrame = typeof win.parent !== "undefined" 
      && typeof win.parent.postMessage !== "undefined" 
      && parent !== win;

    if (isEmbeddedIFrame) {
      const parentWindow = (window as any).parent;
      const parentIFrame = (window as any).parentIFrame;
      
      setTimeout(() => {
        parentWindow.postMessage(JSON.stringify({
          type: 'sectionChange',
          iframeId: parentIFrame.getId(),
          name: sectionName
        }), "*");
      }, 100);
    }
  }
}
