
import React from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Typography, CircularProgress } from '@material-ui/core';
import { themeTypes } from 'app/models';
import classNames from 'classnames';

const NS = 'LoaderOverlay';

export default function LoaderOverlay({theme, message}: IStateFromProps & IDispatchFromProps) {
  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  return (
    <div className={classNames({
      [style.root]: true,
      [style.rootIsDark]: isDark
    })}>
      <div className={style.loader}>
        <CircularProgress />
        <Typography variant="subtitle1" align="center" 
          className={classNames({
            [style.message]: true,
            [style.messageIsDark]: isDark
          })} >
          {message}
        </Typography>
      </div>
    </div>
  )
}

