import {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {TypographyOptions} from "@material-ui/core/styles/createTypography";
import { PaletteOptions, SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";
import {
    BookingType,
    IBookingMenuOption, IBookingPayment, IBookingStatus, IBookingTag,
    ILayoutMinimal, ISavedBookingSelectedOptions,
    IServicePaymentDetails, ITableItem, servicePaymentType
} from "shared-types/index";
import {Moment} from "moment";
import {bookingStatusType} from "shared-types/bookingStatusTypes";

export interface IManageBookingCustomer {
  firstName: string; // "Peter"
}

export enum wrapperStyleType {
    narrow = 'narrow',
    stacked = 'stacked',
    standard = 'standard',
    wide = 'wide'
}

export enum themeTypes {
    outlinedLight = 'outlined-light',
    outlinedDark = 'outlined-dark',
    light = 'light',
    dark = 'dark'
}

export interface IWidgetTypography extends TypographyOptions {
    fontFamily?: string;
}

export interface IWidgetTheme extends ThemeOptions {
    type: themeTypes;
    defaultColors: boolean;
    typography?: IWidgetTypography;
    palette?: IWidgetPalette;
    defaultFont?: string;
}

export interface IWidgetPalette extends PaletteOptions {
    warning?: SimplePaletteColorOptions;
    success?: SimplePaletteColorOptions;
}

export interface IPaymentSummaryMenuOption {
    label: string;
    price: number;
    quantity: number;
    childLineItems?: IPaymentSummaryMenuOption[];
}

export interface IEwayInfo {
    acceptVisa: boolean;
    acceptMasterCard: boolean;
    acceptAmericanExpress: boolean;
    acceptDiners: boolean;
    acceptJcb: boolean;
    clientSideEncryptionKey?: string;
    paymentProvider: paymentProviderType;
}

export interface IStripeInfo {
    publishableKey: string;
    stripe3DEnabled: boolean;
    paymentProvider: paymentProviderType;
}

export enum paymentProviderType {
    eway = 'eway',
    stripe = 'stripe'
}

/**
 * Annoyingly this is uppercase first letter when coming from IOwnedVenue.
 * Gets forced to lowercase in AccountService -> getPaymentSettings
 */
export enum paymentProviderTypeUppercase {
    Eway = 'Eway',
    Stripe = 'Stripe'
}

export interface IPaymentSettings {
    paymentProvider: paymentProviderTypeUppercase;
    eway: IEwayInfo;
    stripe: IStripeInfo;
    preAuthReleasingWindow: number;
}

export interface IWidgetSettings {
    maximumPeopleMessage: string;
    dayClosedMessage: string;
    noTablesAvailableMessage: string;
    timeNoLongerAvailableMessage: string;
    showTimeNotAvailable: boolean;
    timeNotAvailableMessage: string;
    maxPeoplePerBooking: number;
    canCustomersChooseSection: boolean;
    termsAndConditionsMessage: string;
    publicTermsAndConditionsMessage?: string;
    preAuthorisationMessage: string;
    bookingCancellationWindow: string; // number as string
    preAuthReleasingWindow?: string;
    theme: themeTypes;
    font?: string;
    accentColour: string;
    button: unknown;
    allowOnlineBookings: boolean;
    onlineBookingsOffMessage: string;
    minMinutesBeforeServiceBooking: number;
    maxDaysInFutureBooking: number;
    tooFarInAdvanceMessage: string;
    disableEditingTimeWindow: number;
    finalWidgetScreenMessage: string;
    minMinutesBeforeBooking: number;
    disableCancelTimeWindowInHours: number;
}


// very similar to IVenue, but not identical
export interface IOwnedVenue {
    id: number;
    name: string;
    country: string;
    address: string;
    suburb: string;
    postcode: string;
    state: string;
    phone: string;
    // city: string; // @toDo check this actually exists on ownedVenue. severity: high
    url: string;
    active: boolean;
    logoUrl: string;
    alternatePhone: string;
    contactEmail: string;
    widgetSettings: IWidgetSettings;
    timeZoneId: string;
    currency: string; // USD, AUD, GBP, etc
    paymentSettings: IPaymentSettings;
    canCustomersCancelBookings: boolean;
    canCustomersEditBookings: boolean;
    canCancelBookingBySms: boolean;
    canEditBookingBySms: boolean;
    analytics: IAnalyticsInfo;
    abcPaymentTimeoutInMinutes?: string;
    smsEnabled: boolean;
}

export interface IAnalyticsItem {
    apiKey: string;
}

export interface IAnalyticsInfo {
    facebook: IAnalyticsItem;
    google: IAnalyticsItem;
}

export interface IScheduleAbstract {
    services: IScheduleServiceAbstract[];
    venueDetails: IOwnedVenue;
    venueId: number;
    blockoutMessage: string;
    isVenueOpen: boolean;
    bookingInterval: number;
    tags: any;
    currentBookingsOnStandbyList?: number;
    maxBookingsOnStandbyList?: number;
}

export interface ISchedule extends IScheduleAbstract {
    services: IScheduleService[];
}

export interface IScheduleServiceAbstract {
    id: string;
    description: string;
    duration: number; // mins
    name: string; // "Breakfast"
    online: boolean;
    paymentDetails: IServicePaymentDetails;
    policyAgreement: string; // null or 'true'
    policyAgreementText: string;
    sections: ISectionOrder[];
    times: IScheduleTime[];
    disabled?: boolean;
    serviceType: string;
}

export interface IScheduleService extends IScheduleServiceAbstract {
    layouts?: ILayoutMinimal[];

    /**
     *  These properties are for Widget V2 - ServiceSelector's type, IScheduleService of app/services/client/client.types
     *  Horita
     */
     minPaxPerBooking?:number;
     isBlockoutPartiallyEnabled?: boolean;
     blockoutMessage?: string;
}

export interface ISectionOrder {
    id: string; // "section_GIYOEZ9CC963K"
    name: string; // "Main Room"
    order: number; // order in list compared with other sections
}


export interface ISectionState {
    id: string; // "section_GIYOEZ9CC963K"
    sectionState: boolean;
    isSectionBlocked: boolean;
}

export interface IScheduleTime {
    expired: boolean;
    name: string; // "6:00am"
    sections: ISectionState[];
    time: string; // "2019-11-14T06:00:00"
    bookingOptionsCount?: number;
    includesWalkInTables?: boolean;
    onlySharedTablesRemain?: boolean;
    isBlockOut?: boolean;
    isStandByListAvailable? : boolean;
}

export interface IAnalyticsKeys {
    facebookApi: string;
    googleApi: string;
}

export interface IVenueMinimal {
    active: boolean;
    name: string;
    phone: string;
    alternativePhone: string; //null
    address?: string; //"20 Mascot Street, , Woy Woy, NSW, Australia, 2256"
    city: string;
    state: string;
    country: string;
    email: string;
    logoUrl?: string;
    widgetSettings?: IWidgetSettings;
    paymentSettings: IEwayInfo | IStripeInfo;
    canCancelBookingBySms: boolean;
    canCustomersCancelBookings: boolean;
    canCustomersEditBookings: boolean;
    canEditBookingBySms: boolean;
}

export enum bookingAction {
    edit = 'edit',
    confirm = 'confirm',
    cancel = 'cancel',
    manageBooking = 'manage-booking',
    payment = 'payment',
    payments = 'payments',
    booking = 'booking',
    abc = 'abc'
}

export interface IAppSettings {
    accountId?: string;
    venueId?: number;
    fromURL?: string;
    startURL?: string;
    theme?: string;
    font?: string;
    accent?: string;

    /**
     * Booking Id could come from the url or saving the booking
     * this reference is used throughout the app rather than the
     * one on the booking
     */
    bookingId?: string;

    tokenId?: string;
    date?: string;
    serviceids?: string;
    covers?: number;
    action?: bookingAction;
    source?: string;
    debug?: string;
    mode: modeType;
    canDeleteBooking: boolean;
    isStyleGuide: boolean;
    eventId?: string;
    privateFunction?: boolean;
    bgColor?: string;
    banner?: string;
}

export interface IWidgetBooking extends IBookingAbstract {
    orgDate?: Moment;
    customer: IWidgetCustomer;
    standByConfirmationExpiry?: Date;
    hasManagerNotes?: boolean;
    hasCustomerNotes?: boolean;
    isGaw?: boolean;
    method?:string;
}

export interface IBookingAbstract {
    _id?: string; // if booking has been saved it should have an _id

    // not sure about these properties. they don't exist when retreiving existing booking
    accountId?: string | boolean; // this probably will change to just be null, rather than supporting boolean
    venueId?: string | boolean; // this probably will change to just be null, rather than supporting boolean
    // venueIndex: number; // don't need this (can work it out from venueId, which is better anyway)

    // @toDo: double check this actually gets used. Seems like 'moment' gets used instead. severity: low
    date?: string | boolean; // this probably will change to just be null, rather than supporting boolean

    selectedMenuOptions?: IBookingMenuOption[];
    selectedUpsellOptions?: IBookingMenuOption[];

    viewDate: string;
    covers: number;
    serviceId: string;
    sectionId: string;
    utcTime: string;
    viewTime: string;
    rhinoTime: string;
    payment: IBookingPayment;

    isFromDiary?: boolean;

    moment: Moment; // gets added in addUrlParamsToBooking (used for events) @toDo determine if this could be a simple date string, rather than a moment object. severity: medium
    serviceIds?: string[]; // gets added in addUrlParamsToBooking

    tags?: IBookingTag[];
    savedTags?: string[]; // just the tag ids

    status?: bookingStatusType;
    serviceName?: string; // "Dinner"
    locked?: boolean;// null
    onlineEditingDisabled?: boolean;// null
    notes?: string;
    bookingType: BookingType;
    primaryTableId?: string;
    tables: ITableItem[];
    onlineCancelDisabled?: boolean;
}

export enum modeType {
    normal = 'normal',
    preview = 'preview'
}

export enum blockNavType {
    becauseOfPayment = 1,
    becauseOfCoversPending = 2
}

export interface IManageBookingError {
    title: string;
    message: string;
}
export interface IWidgetBasicColor {
    main?: string; // hex
    light?: string; // hex
}
export enum colorParamTypes {
    hex = 'hex',
    name = 'name'
}

export enum themeMessageTypes {
    colors = 'colors',
    theme = 'theme',
    font = 'font'
}


export interface IColorsFromQueryString {
    palette: PaletteOptions;
    defaultColors: boolean;
}


export interface IPrepareEwayFunctionResponse {
    status: number;
    data: IPrepareEwayFunctionData;
}

export interface IPrepareEwayDataAbstract {
    success: boolean;
    errorMessage: string;
    formActionUrl: string; // form url (starts with 'https://secure-au.sandbox.ewaypayments.com' + the access code)
    accessCode: string; // big ugly long code. Don't think we need it for anything after this response comes back
    transactionId: string; // comes back from payment summary
    amount: number; // 20
    amountDue: number; // 20
    amountPaid: number; // 0
    responseCodes: unknown; // null
    paymentType: string; // 'FullPayment'
    currencyCode: string; // "AUD"
}

export interface IPrepareEwayData extends IPrepareEwayDataAbstract {
    promotionCode: string;
    purchaseOrderId: number; // 0
    bookingId: string; // "booking_2019-12-12_MJO4TC2R4J38Y_1576112971046",
}


export interface IPrepareEwayFunctionData extends IPrepareEwayDataAbstract {
    people: number; // 20
    startTime: string; // "2020-01-26T17:30:00"
}


// this object also gets used for pre-auth stripe check, but with EWAY_CARDNUMBER and EWAY_CARDCVN set to null
export interface IEwayForm {
    EWAY_CARDNAME: string;
    EWAY_CARDNUMBER: string;
    EWAY_CARDEXPIRYMONTH: string;
    EWAY_CARDEXPIRYYEAR: string;
    EWAY_CARDCVN: string;
}

export interface IPaymentDetailsGenericData {
    name: string;
    cardNumber: string;
    expiryMonth: string;
    expiryYear: string;
    cvc: string;
}

export interface IURLSettings {
    action?: bookingAction;
    source?: string;
}

export interface IManageBookingSubset {
    payment: IBookingPayment;
    rhinoTime: string;
    status?: bookingStatusType;
    customer: IManageBookingCustomer;
    onlineEditingDisabled?: boolean;// null
    locked?: boolean;// null
    viewDate: string;
    viewTime: string;
    bookingType: BookingType;
    covers: number;
    onlineCancelDisabled?: boolean;
}

export interface IBookingResponseData {
    bookingRef: string; // "32582819"
    method: string; // "online"
    time: string; // "2019/11/30 09:30"
    orgTime: string; // "2019/11/30 09:30"
    duration: number; // 30
    people: number; // covers
    customer: IBookingCustomer;
    tables: ITableItem[];
    preferredSectionId: string; // "section_GIYOEZ9CC963K"
    tags: IBookingTag[];
    status: IBookingStatus;
    hasManagerNotes: boolean;
    hasCustomerNotes: boolean;
    bookedAt: string; // "2019-11-29T02:03:02.8191738+00:00"
    paymentSummary: IWidgetPaymentSummary; // null
    serviceId: string; // "service_SY3N885VPA6GG_1570589968666"
    lastModifiedDate: string; // "2019-11-29T02:03:02.8191738+00:00"
    serviceName: string; //  "Morning Tea"
    locked: boolean;
    onlineEditingDisabled: boolean;
    paymentPending: IBookingPayment; // null
    venueID: unknown;
    _id: string; // "booking_2019-11-30_ZDUVF7R0HU4D3_1575032582882"
    type: string; // "booking"
    bookingType: BookingType;
    selectedOptions: ISavedBookingSelectedOptions[];
    standByConfirmationExpiry: Date;

    // when customer is null, these may exist
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
    phone?: string;
    notes?: string;
    onlineCancelDisabled?: boolean;
}

export interface IWidgetCustomer {
    _id?: string;
    company?: string;
    email: string;
    firstName: string;
    lastName: string;
    notes?: string;
    phone: string;
    phoneNational?: string;
    subscribed: boolean; // this corresponds to the terms and conditions checbox
}

export interface IBookingCustomer extends IWidgetCustomer {
    birthdayDate: unknown;
    birthdayMonth: unknown;
    birthdayYear: unknown;
    lastModifiedDate: string; // "2019-11-29T02:11:07.2713648+00:00"
    managerNotes: string;
    noShowCount: number; // 0
    phone2: string;
    postcode: unknown;
    streetAddress: unknown;
    suburb: unknown;
    tags: IBookingTag[];
    type: string; // "customer"
}



export interface IPrivateFunction {
    isPaid: boolean;
    time: string;
    people: number;
    status: IBookingStatus;
    serviceId: string;
    serviceName: string;
    locked: boolean;
    onlineEditingDisabled: boolean;
    customer: IBookingCustomer;
    preferredSectionId?: string; // "section_GIYOEZ9CC963K"
    sectionId?: string; //"section_AEMOGNHCZ4IV0"
    tags?: IBookingTag[] | IBookingTag;

    paymentPending?: IBookingPayment;
    paymentSummary?: IWidgetPaymentSummary; // null
    onlineCancelDisabled?: boolean;
}

export enum preAuthStatus {
    completelyCharged = 'Completely Charged',
    released = 'Released',
    holding = "Holding"
}

export interface IWidgetPaymentSummary {
    amountPaid: number; // 10
    amountDue: number;
    paymentType: servicePaymentType;
    amount: number;
    currencyCode: string; // "AUD"
    hasPromotion: boolean;
    paymentDate: string;
    preAuthChargeIfWithinHours: number; // 24
    preAuthChargedAmount: number; // 0
    preAuthChargedDate: string;
    preAuthHeldDate: string; // "2020-12-29T06:03:26.8988100+00:00"
    preAuthReleasedAmount: number; // 10
    preAuthReleasedDate: string; // "2020-12-29T06:18:14.6736843+00:00"
    preAuthStatus: preAuthStatus;
    promotionCode: unknown;
    transactionId: unknown;
    cardLast4?: string; //Last four digits used to pay for this booking, if available.
}


export enum BreakPoints {
    NARROW_BP = 320, // iPhone 5 (also affects Wix mobile which forces all mobiles to 320px)
    STACKED_BP = 600, // nearly all mobiles will fit into this breakpoint
    STANDARD_BP = 768
}

export interface IApplyPromoCode {
    codeFailMsg: bookingErrorType | string;
    payment: IPromoCodeResponseData;
}

export interface IPromoCodeResponseData {
    amountDue: number;
    discountAmount: number;
    promotionCode?: string;
}

export interface IStripePaymentError {
    stripeError: stripe.Error; // comes from 'stripe.createPaymentMethod'
    backEndError: IErrorResponse; // comes from back end 'paynow'
}

export interface IProcessPayment {
    errorPayload?: IStripePaymentError | bookingErrorType;
    successPayload?: IStripePaymentSuccessData | ISavePreAuthResponseData;
}

export interface IErrorResponseData {
    message: string; // comma separated string of errorCodes (eg V6101,V6106,V6110)
}

export interface IErrorResponse {
    statusText: string; // Server Error
    status: number; // 500
    data?: IErrorResponseData;
}

export interface IStripePaymentSuccessData {
    transactionId: string;
    amountPaid: number;
    response: stripe.PaymentMethodResponse;
}

export interface ISavePreAuthResponseData {
    tokenCustomerId?: number;
    amountPaid?: number;
}

export interface IPrepareEwayDataAbstract {
    success: boolean;
    errorMessage: string;
    formActionUrl: string; // form url (starts with 'https://secure-au.sandbox.ewaypayments.com' + the access code)
    accessCode: string; // big ugly long code. Don't think we need it for anything after this response comes back
    transactionId: string; // comes back from payment summary
    amount: number; // 20
    amountDue: number; // 20
    amountPaid: number; // 0
    responseCodes: unknown; // null
    paymentType: string; // 'FullPayment'
    currencyCode: string; // "AUD"
}

export interface IPrepareEwayData extends IPrepareEwayDataAbstract {
    promotionCode: string;
    purchaseOrderId: number; // 0
    bookingId: string; // "booking_2019-12-12_MJO4TC2R4J38Y_1576112971046",
}


export interface IPrepareEwayFunctionData extends IPrepareEwayDataAbstract {
    people: number; // 20
    startTime: string; // "2020-01-26T17:30:00"
}

export interface IPayNowParams {
    venueId: number;
    bookingId?: string;
    eventId?: string;
    stripePaymentToken?: string;
}


export interface IFunctionPaymentSummaryResponseData {
    success: boolean;
    errorMessage: string;
    responseCodes: string; // "A2000"
    transactionId: string; // "ch_1G2qcECJ4FeOOjFXQbW4tajU"
    amount: number; // 1
    amountDue: number; // 1
    amountPaid: number; // 1
    paymentType: servicePaymentType;
    currencyCode: string;
}


export interface ICreditCardEncrypted {
    title: string;
    name: string;
    number: string;
    expiryMonth: string;
    expiryYear: string;
    cvn: string;
}

export interface ISavePreAuthData {
    venueId: number;
    bookingId: string; // "booking_2019-12-12_MJO4TC2R4J38Y_1576112971046",
    customerId: string;
    creditCardDto: ICreditCardEncrypted;
    preAuthReleasingWindow: number; // 24, // hours
    stripePaymentToken?: string; // stripe.Token -> id (only needed for stripe payments)
}
export interface IErrorResponseData {
    message: string; // comma separated string of errorCodes (eg V6101,V6106,V6110)
}

export interface IProcessStripePayment {
    errorStripePayload?: IStripePaymentError;
    errorPreauthPayload?: bookingErrorType;
    successPayload?: ISavePreAuthResponseData;
}

export enum bookingSuccessType {
    bookingConfirmed = 'bookingConfirmed'
}

export enum bookingErrorType {
    conflict = 'conflict',
    duplicate = 'duplicate',
    bookingServerError = 'bookingServerError',
    paymentNotSetup = 'paymentNotSetup',
    badRequest = 'badRequest',
    UKNOWN_USERPAYMENTERROR = 'UKNOWN_USERPAYMENTERROR',
    STANDBY_HAD_PAYMENT = 'STANDBY_HAD_PAYMENT',
    paymentServerError = 'paymentServerError',
    paymentTimeout = 'paymentTimeout',
    bookingExpired = 'bookingExpired',
    pageError = 'pageError',
    emptyError = 'emptyError',
    serverDown = 'serverDown',
    noAccount = 'noAccount',
    linkExpired = 'linkExpired',
    timeout = 'timeout',
    clientError = 'clientError',
    inactive = 'inactive',
    cancellationFail = 'cancellationFail',
    bookingExpiredBeforePayment = 'bookingExpiredBeforePayment',
    paymentError = 'paymentError',
    paymentAuthError = 'paymentAuthError',
    AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
    confirmationError = 'confirmationError',
    bookingAlreadyConfirmed = 'bookingAlreadyConfirmed',
    cancellationError = 'cancellationError',
    bookingAlreadyCancelled = 'bookingAlreadyCancelled',
    bookingCancelled = 'bookingCancelled',
    duplicateFunctionPayment = 'duplicateFunctionPayment',
    paymentProviderNotSetup = 'paymentProviderNotSetup',
    publishableKeyMissing = 'publishableKeyMissing',
    onlineBookingsOffMessage = 'onlineBookingsOffMessage',
    timeExpired = 'timeExpired',
    tooFarInAdvanceMessage = 'tooFarInAdvanceMessage'
}

export enum bookingErrorMessageType {
    serverError = 'serverError',
    noAccount = 'noAccount',
    linkExpired = 'linkExpired',
    timeout = 'timeout',
    paymentTimeout = 'paymentTimeout',
    clientError = 'clientError',
    noTables = 'noTables',
    timeNoLongerAvailableMessage = 'timeNoLongerAvailableMessage',
    bookingError = 'bookingError',
    inactive = 'inactive',
    paymentError = 'paymentError',
    cancellationFail = 'cancellationFail',
    confirmationError = 'confirmationError',
    bookingAlreadyConfirmed = 'bookingAlreadyConfirmed',
    cancelBookingError = 'cancelBookingError',
    bookingAlreadyCancelled = 'bookingAlreadyCancelled',
    functionPayment = 'functionPayment',
    paymentProviderNotSetup = 'paymentProviderNotSetup',
    publishableKeyMissing = 'publishableKeyMissing',
    emptyError = 'emptyError',
    onlineBookingsOffMessage = 'onlineBookingsOffMessage',
    tooFarInAdvanceMessage = 'tooFarInAdvanceMessage'
}

export interface IBookingErrorMinimal {
    heading: string;
    messageType: bookingErrorMessageType;
    name: bookingErrorType;
    message: string;
    buttonText: string;
}

export interface IBookingError extends IBookingErrorMinimal {
    data: string;
    status: number;
}

export interface IEwayErrorCode {
    code: string;
    description: string;
}

export interface IFinilisePayment3DSecureParams {
    bookingId: string;
    venueId: number;
    TransactionId: string;
    AmountPaidId: number;
}

export interface IStripePaidData {
    transactionId: string;
    amountPaid: number;
}

export interface IProcessEwayPaymentResponse {
    AccessCode: string; // big ugly long code. Don't think we need it for anything after this response comes back
    RedirectUrl: string; // url containing booking id, but not sure if it actually gets used for anything (i think not)
    IsComplete: boolean; // doesn't seem to get used for anything
    Errors: string; // comma-separated list of Eway error codes. This gets used to determine if payment was successful. Will be null if no errors.
}


export interface IEwaySummaryResponseData {
    success: boolean;
    errorMessage: string; // "",
    formActionUrl: string; // always seems to be null
    transactionId: string; // "21867063"
    amountPaid: number; // 10
    accessCode: string; // always seems to be null
    responseCodes: string; // "A2000"
    purchaseOrderId: number; // 0
}

export interface IPromoCodeResponseData {
    amountDue: number;
    discountAmount: number;
    promotionCode?: string;
}

export interface IHasPromoCodeResponseData {
    showPromoCode: boolean;
}
export enum loadStatus {
    idle = 'idle',
    loading = 'loading',
    loaded = 'loaded',
    failed = 'failed',
    success = 'success'
}

export interface IHasPromoCode {
    status: loadStatus;
    showPromoCode: boolean;
}


export enum analyticsParams {
    bubble = 'bubble'
}

export interface IAppQueryStringParams {
    mode?: modeType;
    colors?: string; // interchangable with 'accent'
    accent?: string; // interchangable with 'colors'
    font?: string;
    theme?: string;
    accountid: string;
    venueid?: string;
    fromurl?: string;
    bookingid: string;
    token?: string;
    date?: string;
    serviceids?: string;
    covers?: string;
    source?: string;
    debug?: string;
    bg?: string;
    banner?: string;
    analytics?: analyticsParams;
    mobilescroll?: 'none';
}

export interface ISetupPreAuth3DData {
    venueId: number;
    bookingId: string;
  }

  export interface ISetupPreAuth3DResponse {
    statusText: string; // 'OK'
    status: number; // 200
    data: ISetupPreAuth3DResponseData;
  }

  export interface ISetupPreAuth3DResponseData {
    clientSecret: string;
    setupIntentId: string;
    tokenCustomerId: string;
  }


  export interface IFinalizePreAuth3DData {
    venueId: number;
    bookingId: string;
    setupIntentId: string;
    tokenCustomerId: string;
  }

  export interface IFinalizePreAuth3DResponse {
    statusText: string; // 'OK'
    status: number; // 200
  }

  export interface IFinalisePayment3DSecurePFParams {
    eventId: string;
    venueId: number;
    transactionId: string;
    amountPaidId: number;
  }
  