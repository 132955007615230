import {
  IPaymentDetails,
  ISectionLayoutItem,
  ServiceEventType,
  IBookingTag,
  IServiceItem,
  IServiceLayoutItem,
  IBlockout,
  IAdditionalPaymentDetails,
  IPaymentDetailsBase,
  IVenue,
  IPosIntegationDetails,
  ITableItem,
  DiaryMobileVersion,
} from 'shared-types/SharedTypes';
import { IOwnedVenue, IPaymentSettings } from 'shared-types/WidgetTypes';

export interface IDiaryService {
  _id: string; // "service_MWNR2VZS6BAO1_1587075416493"
  type: string; // "AllDay", 'Event', 'event'
  eventType?: ServiceEventType;
  bookingPAX?: number;
  sectionLayouts: ISectionLayoutItem[];
  Colour: string; // "booking-time-orange"
  active: boolean;
  additionalPaymentDetails: IAdditionalPaymentDetails[];
  bookingDuration: number; // 30
  description: string;
  index: number; // 0
  intervalsCustom: IServiceInterval[];
  name: string; // "All day"
  online: boolean;
  paymentDetails: IPaymentDetails;
  policyAgreement: boolean;
  policyAgreementText: string; // "Agree to stuff"
  tablesIdsList: string[]; // ["table_S2HVR52GTHI60_1585723196136"]
  customDurations?: ICustomDuration[];

  /**
   * Horita
   * Copied from sharetypes / IServiceItem
   */
  enableCustomPaxPerIntervals: boolean;
  maxCoversPerInterval: number;
  maxBookingsPerInterval: number;

  boookingInterval?: number; // spelt wrong on back end as well, so can't change easily
  firstBookingTime?: Date;

  // these times get converted from string to Date (originall IEditScheduleService)
  startTime: Date;
  endTime: Date;
  lastBookingTime: Date;
}

export interface ICustomDuration {
  bookingDuration: number;
  maxPeople: number;
  minPeople: number;
}

export interface IServiceInterval {
  intervalDuration: string; // "5";
  times: string; // "12:30 pm";
  values: string; // "";
}

export interface IEvent {
  eventType: ServiceEventType;
  description: string;
  additionalPaymentDetails: unknown[];
  date: Date | string; //"2022/05/26 00:00"
  firstBookingTime: Date | string; // "04:59"
  boookingInterval: string; // '30'
  bookingDuration: number; // 60
  people?: number;
  customerId: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;

  emailNotes?: string;
  endTime: Date; // "04:59"
  internalDescription?: string;
  lastBookingTime: Date; // "04:59"
  maxBookingsPerInterval?: number;
  maxCoversPerInterval?: number;
  minPaxPerBooking?: number;
  name?: string;
  online?: boolean;
  paymentDetails?: IPaymentDetailsBase;
  policyAgreement?: boolean;
  policyAgreementText?: string;
  sectionLayouts?: {
    layoutId: string;
    sectionId: string;
  }[];
  startDateTime: Date | string;
  endDateTime: Date | string;
  startTime: Date; // "04:59"
  type?: string; //"event"
  enableCustomPaxPerIntervals?: boolean;
  intervalsCustom?: IServiceInterval[];
  _id?: string;
  _rev?: string;
}

// when schedule is loaded straight from CouchDB it only has simple string times "05.00", whereas IEvent has date objects
export interface IEventRawTimes
  extends Omit<IEvent, 'startTime' | 'endTime' | 'firstBookingTime' | 'lastBookingTime'> {
  startTime: string; // '05.00'
  endTime: string; // '05.00'
  firstBookingTime: string; // '05.00'
  lastBookingTime: string; // '05.00'
  date: string; // "2023/02/24 11:55"
  startDateTime: string; // 2023/01/03 15:38"
  endDateTime: string; // 2023/01/03 15:38"
}

export interface IDiaryVenueMinimal {
  id: number; // 4021
  accentColour: string;
  active: boolean;
  activeModifiedAt?: string; //"2019-12-12T22:15:50.4330711+00:00"
  country: string; // "AU"
  font: string;
  isPosActive: boolean;
  isUsingAccountWidgetSettings: boolean;
  name: string;
  renewalDate: string; //"2019-12-12T22:15:50.4330711+00:00"
  state: string; // "NSW"
  subscriptionType: string; // "Perpetual"
  theme: string;
  widgetVersion: number; // 2
}

export interface IDiaryVenue {
  id: number; //9
  accountId: string; //"109d9819-d0ee-479c-87b8-855f8bbfb266"
  active: boolean;
  address: string; //"20 Mascot Street, , Woy Woy, NSW, Australia, 2256"
  alternativePhone: string; //null
  couchName: string; //"db9"
  couchUrl: string; //"http://10.211.55.3:5984/db9"
  country: string; //"AU"
  createdAt: string; //"2020-04-01T06:39:37.5265894+00:00"
  email: string; //"jim@nowbookit.com"
  enableSmsWaitList: boolean;
  isCovidGuestMode: boolean;
  isGiftCardActive: boolean;
  isOnlineCustomersEnabled: boolean;
  isPOSActive: boolean;
  isPaymentsConfigured: boolean;
  isTakeAwayMode: boolean;
  isTelemetryEnabled: boolean;
  maxBookingOptionsPerInterval: number;
  maxPeoplePerOnlineBooking: number;
  name: string; //"Pangolin Dreams"
  password: string; // null
  phone: string; //"+61 414 000 111"
  pins: string[];
  postcode: string; //"2000"
  reducedCouchName: string; //"db9r"
  reducedCouchPassword: string; //null
  reducedCouchUrl: string; //"http://10.211.55.3:5984/db9r"
  reducedCouchUsername: string; //null
  renewalDate: string; //"2020-04-01T06:39:29.482+00:00"
  standByList: boolean;
  state: string; //"NSW"
  subscriptionType: string; //"Perpetual"
  suburb: string; //"Woy Woy"
  tags: IBookingTag[];
  timeZoneId: 'Australia/Sydney';
  url: string; //null
  username: string; //null
  posIntegrations?: IPosIntegationDetails[];
  smsEnabled: boolean;
  standByListEmail?: boolean;
  standByListSMS?: boolean;
  currency?: string;
  diaryMobileVersion?: DiaryMobileVersion;
  enableNewCustomerForm?: boolean;
  enableBookedByOnDiary?: boolean;
  titleSubscription?: string;
  defaultEmailSubscribed?: boolean;
  paymentSettings?: IPaymentSettings;
  isDiaryMobileActive?: boolean;
}

export interface IDiarySchedule {
  _id: string; // "schedule_day_5_49O725W7J5F15_2020-08-19"
  services: IDiaryService[];
  isOpen: boolean;
  serviceLayouts?: IServiceLayoutItem[];

  blockouts: IBlockout[];
  boookingInterval: number; //15
  dayName: string; // "Friday"
  dayNum: string | number; // 5
  order: number; // 2
  date?: any;
}

export interface ICoversBySection {
  sectionCovers: number;
  sectionMarker: string;
}

export interface IScheduleInterval {
  breached?: number; // 0
  coversReached?: number; // 0
  maxCovers?: number; // undefined
  time: Date; // Tue Nov 24 2020 06:00:00 GMT+1100 (Australian Eastern Daylight Time) {}
  warning?: number; // 0
  coversTotal?: number;
  coversBySection?: Map<string, ICoversBySection>;
}

// when schedule is loaded straight from CouchDB it only has simple string times "05.00", whereas IDiarySchedule has date objects
export interface IDiaryScheduleRawTimes extends Omit<IDiarySchedule, 'services'> {
  services: IEditScheduleService[];
}

export interface ISectionGroup {
  _id?: string; //section-group_L8NQA177L51I9_1664414136056
  name: string;
  order: number;
  sectionIds: string[];
}

export interface ISection {
  _id?: string; //section-group_L8NQA177L51I9_1664414136056
  name: string;
  order: number;
  tableNumber: number;
  tablesStartAt: number;
  type: string; //section
}

export interface IRole {
  name: string;
}

export interface IAccount {
  accentColour?: string; // "hex,512da8,ffff00"
  accountId?: string;
  accountPhone: string; // "+61 414 888 999"
  active: boolean;
  alternativePhone: string;
  businessAddress: string;
  businessId: string;
  businessName: string;
  businessUrl: string;
  contactNumber: string; // "+61414666555"
  country: string; // "AU"
  createdAt?: string; // "2019-08-22T05:22:59.0148094+00:00"
  currentBookingService: unknown;
  email: string;
  firstName: string;
  font?: string; //
  surname: string;
  id: string; // "109d9819-d0ee-479c-87b8-855f8bbfb266"
  phone?: string; //
  pin?: any;
  logoUrl: string;
  ownedVenues: IOwnedVenue[];
  postcode: string;
  roles?: IRole[];
  state: string;
  suburb: string;
  theme?: string; // "light"
  venues?: IDiaryVenueMinimal[];
  widgetVersion?: number; // 2
}

/** Contains the amount details spend by a customer for a specific venue */
export interface IAmountSpendInfo {
  averageSpend: number;
  customerId: string;
  totalSpend: number;
}

/** Contains the items purchased by the customer till date */
export interface IPurchasedItemInfo {
  transactionDateTime: string;
  sku: string;
  productName: string;
  description: string;
  quantity: string;
  unitPrice: number;
  salesCategoryName: string;
  customerId: string;
  Id: string;
  Expr1: string;
}

export enum ApiStatus {
  isOnline = 'isOnline', // api is reachable
  isOffline = 'isOffline',
}

export enum CouchEndpointStatus {
  isReachable = 'isReachable', // remote couch db is reachable
  isUnreachable = 'isUnreachable',
}

export enum CouchStatus { // various states of couch, which can be for remote or local db
  setup = 'setup',
  online = 'online', // @todo: I think we can remove 'online', since 'uptodate' gets used instead. severity: medium
  offline = 'offline',
  sending = 'sending',
  receiving = 'receiving',
  syncing = 'syncing',
  uptodate = 'uptodate',
}

// see webpackConfig -> DefinePlugin
export const appValues = {
  // @ts-ignore
  VERSION: VERSION, // defined in app-version.json (via webpack.DefinePlugin)
  // @ts-ignore
  ENVIRONMENT: ENVIRONMENT,
  // @ts-ignore
  SKIP_CACHE: SKIP_CACHE,
  // @ts-ignore
  IS_LOCAL: IS_LOCAL,
};

// @todo: could be replaced by webpack.definePlugin (same as VERSION). severity: low
export const Environment = {
  Value: appValues.ENVIRONMENT || 'remote', // defaults to remote if ENV variable doesn't exist
  IsLocal: appValues.IS_LOCAL,
};

/**
 * IEditScheduleService is the same as IDiaryService except that while being edited the times are stored as strings
 */
export interface IEditScheduleService
  extends Omit<IDiaryService, 'startTime' | 'endTime' | 'lastBookingTime'> {
  startTime: string;
  endTime: string;
  lastBookingTime: string;
}

export interface IPouchDBInstance {
  allDocs?: (param: any) => any;
  put?: (param: any) => any;
  get?: (param: any) => any;
  remove?: (param: any) => any;
  bulkDocs?: (param: any) => any;
}

export interface IScheduleExtensionAddOns {
  addEvents: (schedule: IDiarySchedule, date: Date) => Promise<IDiarySchedule>;
  addBlockouts: (schedule: IDiarySchedule, date: Date) => Promise<IDiarySchedule>;
  addServicesSections: (schedule: IDiarySchedule, date: Date) => IDiarySchedule;
  convertServiceTimesToDates: (
    services: IEditScheduleService[],
    timeZoneId?: string,
    date?: Date
  ) => IDiaryService[];
}
