// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loaderWrap--n565\\+{width:100%;height:200px;display:flex;justify-content:center;align-items:center}.loaderIsLight--lWtGM{color:#fff}.loaderIsDark--uJ\\+jJ{color:#000}.textIsDark--4pt16{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrap": "loaderWrap--n565+",
	"loaderIsLight": "loaderIsLight--lWtGM",
	"loaderIsDark": "loaderIsDark--uJ+jJ",
	"textIsDark": "textIsDark--4pt16"
};
export default ___CSS_LOADER_EXPORT___;
