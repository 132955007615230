import { grey, deepOrange, blue, blueGrey, teal, indigo, red, orange, green, lightBlue, lightGreen, amber } from '@material-ui/core/colors';
import { Color } from '@material-ui/core';
import { themeTypes, IWidgetTheme } from 'app/models';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { Overrides } from '@material-ui/core/styles/overrides';
import { UtilsService } from 'app/services/utils/utils.service';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import appValues from 'app/constants/appValues';
import { colorParamTypes, themeMessageTypes, IPreviewUpdate, IWidgetBasicColor, IColorsFromQueryString, IWidgetPalette } from './theme.types';
import { SimplePaletteColorOptions } from '@material-ui/core/styles';

const NS = 'ThemeColorsService';

export class ThemeColorsService {
  static getThemeDefaults(type: themeTypes = themeTypes.light, isPaperTransparent = false) {

    const palette: any = {
      background: {
        default: '#f5f5f5'
      },
      warning: {
        main: '#f0ad4e',
        light: '#fcf8e3',
        dark: '#8a6d3b'
      },
      success: {
        main: '#c3e6cb',
        light: '#c3e6cb',
        dark: '#155724'
      },
      error: {
        main: '#f44336',
        light: '#ef9a9a',
        dark: '#b71c1c'
      }
    };

    if (type === themeTypes.outlinedDark || type === themeTypes.dark) {
      if (isPaperTransparent) {
        palette.background.paper = `rgba(${UtilsService.hexToRgb('#424242')}, 0.6)`;
      }
      return {
        ...palette,
        primary: lightBlue,
        secondary: lightBlue,
      };
    } else {
      if (isPaperTransparent) {
        palette.background.paper = `rgba(${UtilsService.hexToRgb('#fff')}, 0.7)`;
      }
      return {
        ...palette,
        primary: blueGrey,
        secondary: grey,
      };
    }
  }

  /**
   * Converts a string name to a Color object, from list of acceptable names
   * @todo finish adding all material colors. severity: low (not being used currently)
   */
  static getColorByName(name: string): Color {
    switch (name.toLowerCase()) {
      case 'blue': return blue;
      case 'bluegrey': return blueGrey;
      case 'grey': return grey;
      case 'green': return green;
      case 'red': return red;
      case 'teal': return teal;
      case 'indigo': return indigo;
      case 'deeporange': return deepOrange;
      case 'orange': return orange;
      case 'lightblue': return lightBlue;
      case 'lightgreen': return lightGreen;
      default: return grey;
    }
  }

  static listenForPreviewUpdates(): Observable<IPreviewUpdate> {

    return fromEvent(window, 'message')
      .pipe(
        map((evt: any) => {
          if (evt.origin + '/' !== appValues.URLS.admin) {
            return null; // don't want to affect any messages from the iframe resizer's messages
          }
          const dataPair: string = evt.data.split('=');
          const type: string = dataPair[0];
          if (type !== themeMessageTypes.colors && type !== themeMessageTypes.theme && type !== themeMessageTypes.font) {
            return null; // block any messages that might be for something other than theme color changes
          }

          return {
            type,
            value: dataPair[1]
          };
        })
      );
  }

  static getColorsFromQueryString(colorsParam: string, palette: IWidgetPalette = {}, themeType: themeTypes = themeTypes.light): IColorsFromQueryString {
    let defaultColors = true;
    const colors: string[] = colorsParam ? colorsParam.split(',') : [];
    const type: string = colors[0];
    const primary: string = colors[1];
    const secondary: string = colors[2] || primary; // uses primary color if only 1 supplied

    if (primary) {
      if (type === colorParamTypes.hex) {
        // if using hex values
        palette.primary = {
          main: `#${primary}`
        };
      } else if (type === colorParamTypes.name) {
        // if using named color groups (eg 'blue')
        palette.primary = this.getColorByName(primary);
      }
      defaultColors = false;
    } else {
      palette.primary = this.getThemeDefaults(themeType).primary;
    }

    if (secondary) {
      if (type === colorParamTypes.hex) {
        // if using hex values
        palette.secondary = {
          main: `#${secondary}`
        };
      } else if (type === colorParamTypes.name) {
        // if using named color groups (eg 'blue')
        palette.secondary = this.getColorByName(secondary);
      }
    } else {
      palette.secondary = this.getThemeDefaults(themeType).secondary;
    }

    return { palette, defaultColors };
  }

  static setThemeValues(themeType: themeTypes, theme: IWidgetTheme): IWidgetTheme {

    if (!theme.overrides) {
      theme.overrides = {};
    }

    // must come after colors, as it will use them in overrides
    switch (themeType) {
      case themeTypes.outlinedLight:
        theme = ThemeColorsService.getThemeOutlinedLight(theme);
        theme.palette && (theme.palette.type = 'light');
        break;
      case themeTypes.outlinedDark:
        theme = ThemeColorsService.getThemeOutlinedDark(theme);
        theme.palette && (theme.palette.type = 'dark');
        break;
      case themeTypes.dark:
        theme = ThemeColorsService.getThemeDark(theme);
        theme.palette && (theme.palette.type = 'dark');
        break;
      default:
        theme.type = themeTypes.light;
        theme.palette && (theme.palette.type = 'light');
    }

    return theme;
  }

  static getCommonThemeStyles(theme: IWidgetTheme) {
    const primary: Color & IWidgetBasicColor = theme.palette.primary as Color & IWidgetBasicColor;
    const secondary: Color & IWidgetBasicColor = theme.palette.secondary as Color & IWidgetBasicColor;
    const textColor1: string  = primary[800] || primary.main;
    const textColor2: string  = primary[800] || secondary.main;

    const colorError: string = (theme.palette.error as SimplePaletteColorOptions).main;

    // this doesn't get added until after initial load, so must have a null check
    const colorWarning: string = theme.palette.warning ? (theme.palette.warning as SimplePaletteColorOptions).main : '#000';
    const colorSuccess: string = theme.palette.success ? (theme.palette.success as SimplePaletteColorOptions).dark : '#000';

    return {primary, secondary, textColor1, textColor2, colorError, colorWarning, colorSuccess};
  }

  /**
   * returns `overrides` object for Material UI date picker using `outlined-light` theme
   */
  private static getMuiPickersThemeOutlinedLight(primary: Color & IWidgetBasicColor, secondary: Color & IWidgetBasicColor): MuiPickersOverrides {

    const textColor1: string  = primary[800] || primary.main;
    const textColor2: string  = primary[800] || secondary.main;

    return {
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: textColor2
        },
      },
      MuiPickersDay: {
        day: {
          color: textColor2,
        },
        daySelected: {
          backgroundColor: 'white',
          color: textColor2,
          border:`1px solid ${textColor2}`,
          '&:hover': {
            color: 'white',
            backgroundColor: primary[500] || primary.main,
          }
        },
        dayDisabled: {
          color: grey.A100,
        }
      },
      // MuiPickersDatePickerRoot: {

      // }
    };
  }


  /**
   * returns `overrides` object for Material UI date picker using `outlined-dark` theme
   */
  private static getMuiPickersThemeOutlinedDark(primary: Color & IWidgetBasicColor, secondary: Color & IWidgetBasicColor): MuiPickersOverrides {

    const textColor1: string  = primary[800] || primary.main;
    const textColor2: string  = primary[800] || secondary.main;

    return {
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: textColor2
        },
      },
      MuiPickersDay: {
        day: {
          color: textColor2,
        },
        daySelected: {
          color: textColor2,
          border:`1px solid ${textColor2}`,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: primary[500] || primary.main,
          }
        },
        dayDisabled: {
          color: grey.A100,
        }
      }
    };
  }


  private static getThemeOutlinedLight(theme: IWidgetTheme): IWidgetTheme {
    theme.type = themeTypes.outlinedLight;

    const primary: Color & IWidgetBasicColor = (theme.palette && theme.palette.primary || this.getThemeDefaults(theme.type).primary) as Color & IWidgetBasicColor;
    const secondary: Color & IWidgetBasicColor = (theme.palette && theme.palette.secondary || this.getThemeDefaults(theme.type).secondary) as Color & IWidgetBasicColor;
    theme.overrides = {
      ...theme.overrides,
      ...this.getMuiPickersThemeOutlinedLight(primary, secondary)
    };

    return theme;
  }

  private static getThemeOutlinedDark(theme: IWidgetTheme): IWidgetTheme {
    theme.type = themeTypes.outlinedDark;

    const primary: Color & IWidgetBasicColor = (theme.palette && theme.palette.primary || this.getThemeDefaults(theme.type).primary) as Color & IWidgetBasicColor;
    const secondary: Color & IWidgetBasicColor = (theme.palette && theme.palette.secondary || this.getThemeDefaults(theme.type).secondary) as Color & IWidgetBasicColor;
    theme.overrides = {
      ...theme.overrides,
      ...this.getMuiPickersThemeOutlinedDark(primary, secondary)
    };

    return theme;
  }

  /**
   * returns theme with overrides needed for `dark` palette type
   */
  private static getThemeDark(theme: IWidgetTheme): IWidgetTheme {
    theme.type = themeTypes.dark;

    const primary: Color = (theme.palette && theme.palette.primary || this.getThemeDefaults(theme.type).primary) as Color;
    const secondary: Color & IWidgetBasicColor = (theme.palette && theme.palette.secondary || this.getThemeDefaults(theme.type).secondary) as Color & IWidgetBasicColor;

    UtilsService.fillWithEmpty(theme, ['overrides', 'MuiPickersCalendarHeader', 'switchHeader']);
    UtilsService.fillWithEmpty(theme, ['overrides', 'MuiPickersYear', 'root']);
    UtilsService.fillWithEmpty(theme, ['overrides', 'MuiPickersYear', 'yearSelected']);

    const overrides: Overrides & MuiPickersOverrides = theme.overrides as Overrides & MuiPickersOverrides;
    (overrides.MuiPickersCalendarHeader.switchHeader as any).color = 'white';
    (overrides.MuiPickersYear.root as any).color = 'white';
    (overrides.MuiPickersYear.yearSelected as any).color = primary[400];

    return theme;
  }

}
