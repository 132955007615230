// @ts-ignore
import * as queryString from 'query-string-es5';

import { IDetectPhone } from "./location.types";
import appValues from "app/constants/appValues";
import { themeTypes, IAppQueryStringParams } from "app/models";

const NS = 'LocationService';

export class LocationService {

  static forceDev = false;

  static isDevEnvironment(): boolean {
    const href = window.location.href;
    return this.forceDev || (href.indexOf('localhost') !== -1 || href.indexOf('dev') !== -1 || href.indexOf('uat') !== -1);
  }
  
  static isLocalDevEnvironment(): boolean {
    const href = window.location.href;
    return href.indexOf('localhost') !== -1;
  }

  static isSSH(url: string = window.location.href): boolean {
    return url.indexOf('https') === 0;
  }

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  static isIOS(): boolean {
    return (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !(window as any).MSStream;
  }

  /**
   * Detects if the outer iframe container (native HTML page) is using a phone, by 
   * checking the window's outerWidth. Also checks for iPhone if you need iPhone specifically,
   * which works on XCode iPhone simulator as well.
   */
  static detectPhone(): IDetectPhone {
    return {
      isPhone: window.outerWidth <= appValues.STACKED_BP,
      isIPhone: (/iPhone/.test(navigator.platform)),
    };
  }

  static getBgColorFromQSParam(bg: string): string {
    if (!bg) {
      return null;
    }
    return bg === 'transparent' ? bg : '#' + bg;
  }

  // TIP: use `appSettings.theme` because `props.theme` has not initialized yet at startup
  static setBgColor(theme: string): void {
    const queryStringParams = queryString.parse(location.search) as unknown as IAppQueryStringParams;
    const bg = queryStringParams.bg;
    if (bg) {
      document.body.style.backgroundColor = LocationService.getBgColorFromQSParam(bg);
    } else {
      const isDark = theme === themeTypes.dark || theme === themeTypes.outlinedDark;
      document.body.style.backgroundColor = isDark ? '#272727' : '#f5f5f5';
    }
  }

  /**
   * Accepts a url and removes either token param and/or edit query string
   */
  static stripCurrentUrl(url: string, removeToken: boolean, removeEdit: boolean): string {

    if (removeToken) {
      const splitUrl: string[] = url.split('&token=');
      url = splitUrl[0];

      if (splitUrl[1] && splitUrl[1].indexOf('&') !== -1) {
        const trailingParams = splitUrl[1].split('&')[1];
        url += '&' + trailingParams;
      }
    }

    if (removeEdit) {
      url = url.replace('edit?', '?');
    }

    return url;
  }
}