import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export class ThemeTypographyService {

  /**
   * Gets the default typography styles for text.
   * Note: 'rem' units are calculated in 10's (eg '2.5rem' = '25px'), using the `htmlFontSize` value below
   * and setting it in `src/app/containers/App/style.css` as well. This way font sizes will scale with the 
   * font settings the user has on their browser.
   */
  static getThemeDefaults(): TypographyOptions {
    return {
      // Tell Material-UI what's the font-size on the html element is (src/app/containers/App/style.css)
      htmlFontSize: 10,

      h1: {
        fontSize: '3rem'
      },
      h2: {
        fontSize: '2.2rem'
      },
      h3: {
        fontSize: '1.8rem'
      },
      h4: {
        fontSize: '1.6rem'
      },
      h5: {
        fontSize: '1.4rem'
      },
      h6: {
        fontSize: '1.3rem'
      },
      subtitle1: {
        fontSize: '1.6rem'
      },
      subtitle2: {
        fontSize: '1.4rem'
      },
      body1: {
        fontSize: '1.4rem'
      },
      body2: {
        fontSize: '1.3rem'
      },
      button: {
        fontSize: '1.6rem'
      },
      caption: {
        fontSize: '1.4rem'
      },
      overline: {
        fontSize: '1.4rem'
      }
    }
  }
}