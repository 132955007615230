import React from 'react';
import style from './style.module.scss';
import { Stepper, Step, StepLabel, makeStyles, createStyles } from '@material-ui/core';
import { IProgressStep, IStateFromProps, IDispatchFromProps } from './types';
import { IWidgetTheme } from 'app/models';
import { ThemeColorsService } from 'app/services/theme/themeColors.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";

const NS = 'ProgressStepper';

/**
 * NOTE:
 * This component gets used with container (steps) and without (payments)
 */

// MuiStepIcon-completed


const useStyles = makeStyles((theme: IWidgetTheme) => {
  const {secondary, textColor1} = ThemeColorsService.getCommonThemeStyles(theme);

  return createStyles({
    root: {
      '& .MuiStepIcon-completed': {
        color: secondary.main
      },
      '& .MuiStepIcon-text': {
        transform: 'translateY(1px)'
      }
    }
  })
});

export default function ProgressStepper({activeStep, wrapperStyle, steps, theme}: IStateFromProps & IDispatchFromProps) {
  const classes = useStyles({theme});
  return (
    <Stepper activeStep={activeStep}
       className={[style.root, classes.root].join(' ')}
       alternativeLabel={IframeResizerService.isStacked(wrapperStyle)}
       color="secondary">
      {steps.map(({label}: IProgressStep, index: number) => {
        return (
          <Step key={index} >
            <StepLabel>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  )
}
